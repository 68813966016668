<template>
  <v-container fluid>
    <v-container>
      <div class="d-flex justify-space-between mb-5">
        <div>
          <h1>Bienvenu !</h1>
        </div>

        <div v-if=" ['Caisse'].includes(UserProfile.role) ">
          <v-btn v-if="(!Request || !Request._id )||  (Request && Request.status =='Terminé') || (Request && Request.status =='Refusé')  " :loading="loading" @click="makeRequest" class="rounded-pill green white--text" >Faire une demande pour vider la caisse</v-btn>
          <v-btn v-else-if=" Request.status =='En cours'   " @click="cancelRequest"  :loading="loading" class="rounded-pill red white--text" >Annuler la demande de vider la caisse</v-btn>
          <v-btn @click="emptyTheCatchRegister" v-else-if=" Request.status =='Accepté'  "  :loading="loading" class="rounded-pill green white--text" >Vider la caisse</v-btn>
        </div>
      </div>
      <div class="my-12">
          <v-row>
              <v-col class="elevation-1 rounded py-2 px-2" cols="12" md="4">
                  <router-link to="/caisse/student">
                      <div class="d-flex flex-column black--text justify-center align-center">
                      <v-icon color="orange">mdi-account</v-icon>
                      <span class="text-h5">Etudiants</span> 
                  </div>
                  </router-link>
              </v-col>
              <v-col class="elevation-1 rounded py-2 px-2" cols="12" md="4">
                  <router-link to="/caisse/stitch">
                      <div class="d-flex flex-column black--text justify-center align-center">
                      <v-icon color="orange">mdi-billboard</v-icon>
                      <span class="text-h5">Point</span> 
                    <!-- <v-icon color="green">mdi-arrow-right</v-icon> -->

                  </div>
                  </router-link>
              </v-col>
          </v-row>
      </div>
    </v-container>

    <v-dialog v-model="editorDialog" max-width="1000px" class="black">
      <editor-x
        :fields="fields"
        :model="Model"
        :callback="callBack"
        title="Enregistrer un(e) étudiant(e)"
        @success="successEditor"
      />
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="1000px" class="black">
      <delete-dialog
        :id="Model && Model._id ? Model._id : ''"
        :callback="callBack"
        @success="successEditor"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defaultMethods, messages } from "@/utils/data";
import { functions } from "@/utils/constant";
import { addStudent, updateFaculty, deleteFaculty } from "@/api/user";
import EditorX from "@/components/universal/EditorX.vue";
import DeleteDialog from "@/components/universal/DeleteDialog.vue";
import { getLevels } from "@/api/universal";
import { manageRequest,cancelRequest,emptyTheCatchRegister } from "@/api/user";

export default {
  name: "Students",
  components: {
    EditorX,
    DeleteDialog,
  },
  data: () => ({
    callBack: "",
    searchData: "",
    dialog: false,
    loading: false,
    headers: [
      {
        text: "Nom",
        align: "start",
  sortable: true,
        value: "name",
      },
      {
        text: "Acronyme",
  sortable: true,
        value: "acronym",
      },
      {
        text: "Description",
  sortable: true,
        value: "description",
      },
      {
        text: "Actions",
        width: "150px",
        align: "left",
        value: "actions",
  sortable: true,
      },
    ],
    editedIndex: -1,
    Model: {},
    defaultItem: {},
    editorDialog: false,
    deleteDialog: false,
  }),
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters(["Students", "Schools", "UserProfile", "Departments","Request"]),
    fields() {
      return [
        {
          name: "lastName",
          label: "Nom",
          type: "String",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
        },
        
      
        {
          name: "currentLevel",
          label: "Niveau",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            data: getLevels(),
          },
        },
        {
          name: "scholarship",
          label: "Bourse",
          icon: "mdi-key",
          type: "String",
          col: 12,
          isRequiredForUpdate: false,
          isRequiredForAdd: false,
          existForUpdate: true,
          existForAdd: true,
        },
        {
          name: "department",
          label: "Option",
          type: "Select",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          select: {
            store: {
              action: this.getDepartments,
              params: { faculty: "" },
              getter: this.Departments,
              fieldName: "name",
            },
          },
        },
        {
          name: "schoolYear",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value:
            this.UserProfile.school &&
            this.UserProfile.school.currentYear &&
            this.UserProfile.school.currentYear._id
              ? this.UserProfile.school.currentYear._id
              : this.UserProfile.school.currentYear,
        },
        {
          name: "school",
          label: "",
          type: "Static",
          col: 12,
          isRequiredForUpdate: true,
          isRequiredForAdd: true,
          existForUpdate: true,
          existForAdd: true,
          value:
            this.UserProfile.school && this.UserProfile.school._id
              ? this.UserProfile.school._id
              : this.UserProfile.school,
        },
      ];
    },
  },

  watch: {},
  methods: {
    ...mapActions(["getStudents", "getSchools", "getDepartments","getRequest"]),
    closeForm() {
      this.Model = {};
      this.editorDialog = false;
      this.editedIndex = -1;
    },

    async init() {
      this.loading = true;
      try {
        await this.getStudents();
        await this.getRequest({type:functions.getRequestTypes(true).emptyCashRegister})
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store,
          messages.failedToLoad("les années")
        );
      }
      this.loading = false;
      
    },
    async makeRequest() {
      this.loading = true;
      try {
        await manageRequest({
          schema:{type:functions.getRequestTypes(true).emptyCashRegister}
        })
        this.init()

      } catch (error) {
        defaultMethods.dispatchError(
          this.$store
        );
      }
      this.loading = false;
    },
    async cancelRequest() {
      this.loading = true;
      try {
        await cancelRequest({
          id:this.Request._id,
        })
        this.init()

      } catch (error) {
        defaultMethods.dispatchError(
          this.$store
        );
      }
      this.loading = false;
    },
    async emptyTheCatchRegister() {
      this.loading = true;
      try {
        await emptyTheCatchRegister()
        this.init()
        location.reload()
      } catch (error) {
        defaultMethods.dispatchError(
          this.$store
        );
      }
      this.loading = false;
    },

    successEditor() {
      this.editorDialog = false;
      this.deleteDialog = false;
      this.init();
    },
    addItem() {
      this.editedIndex = -1;
      this.Model = {};
      this.callBack = addStudent;
      this.editorDialog = true;
    },
    updateItem(item) {
      this.editedIndex = 1;
      this.Model = Object.assign({}, item);
      this.editorDialog = true;
      this.callBack = updateFaculty;
    },

    deleteItem(item) {
      this.deleteDialog = true;
      this.Model = Object.assign({}, item);
      this.callBack = deleteFaculty;
    },
  },
};
</script>

<style >

</style>
